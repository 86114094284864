import React, { useEffect, useReducer, useRef, useState } from 'react';
import './Home.css'; // Make sure this is correctly pointing to your Home.css file
import logo from './Boss_logo.png'; // Importing the logo from the same directory
import * as API from "../utils/api";
import zoomSdk from "@zoom/appssdk";

const Home = () => {


  const reducer = (state, action) => {
    const comments = [...state.comments];
    comments.push(
      ...action.comments.filter(c => !comments.find(comment => comment.id === c.id))
    )
    return { comments: comments }
  }

  const [data, dispatch] = useReducer(reducer, {
    comments: [
      { id: "0", platform: "youtube", timestamp: Date.now(), name: "Alice", message: "Hello, world!", image: "https://th.bing.com/th/id/R.469f512b75a6e3dfca27d3ff0e784b04?rik=v3H1Ojg653JMDg&pid=ImgRaw&r=0&sres=1&sresct=1" },
      { id: "1", platform: "facebook", timestamp: Date.now(), name: "Bob", message: "React is awesome.", image: "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "2", platform: "facebook", timestamp: Date.now(), name: "Charlie", message: "How's it going?", image: "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "3", platform: "youtube", timestamp: Date.now(), name: "Diana", message: "Looking good, React!", image: "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "4", platform: "youtube", timestamp: Date.now(), name: "Eve", message: "This is a message.", image: "https://images.unsplash.com/photo-1518806118471-f28b20a1d79d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "5", platform: "facebook", timestamp: Date.now(), name: "Frank", message: "Virtual meeting, cool!", image: "https://th.bing.com/th/id/R.7ad7ae3636feb990aa628793dc1164bc?rik=uxYefRShHexb%2fw&pid=ImgRaw&r=0&sres=1&sresct=1" }
    ]
  });
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("-1");
  const [scenes, setScenes] = useState([])
  const [scene, setScene] = useState("-1");
  const refCanvas = useRef();
  const [zoomImageId, setZoomImageId] = useState('');

  const [layoutImageId, setLayoutImageId] = useState("");


  // Get Projects
  useEffect(() => {
    API.GetAPI('/api/projects').then(setProjects);
  }, [])


  // Get Scenes for selected project
  useEffect(() => {
    if (project !== '-1') {
      API.GetAPI(`/api/projects/${project}/scenes`).then(setScenes);
    } else setScenes([]);
  }, [project])


  function loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img)
    })
  }


  const onShowScene = async () => {
    try {
      onClearScene();
      const ctx = document.getElementById('canvas').getContext("2d");
      const elements = await API.GetAPI(`/api/projects/${project}/scenes/${scene}/elements`);

      const s = scenes.find(s => s._id == scene);
      const { superchat, superchatX, superchatY, superchatColor, popup, popupX, popupY } = s;

      for (const ele of elements) {
        const { x, y, width, height, color, type, text, url } = ele;
        const fontSize = 19;
        if (type === 'text') {
          ctx.fillStyle = color;
          ctx.textAlign = "left";
          ctx.font = `bold ${fontSize}pt Arial`;
          ctx.textBaseline = "middle";
          ctx.fillText(text, x, y);
        } else if (type === 'rect') {
          ctx.fillStyle = color;
          ctx.fillRect(x, y, width, height);
        } else if (type === 'circle') {
          // Add a purple circle just before the name text
          const radius = 40;
          ctx.fillStyle = color;
          ctx.beginPath();
          ctx.arc(x + 10, y + width / 2 + (radius / 2), height / 2, 0, Math.PI * 2);
          ctx.fill();
        } else if (type === 'image') {
          const image = await loadImage(url);
          ctx.drawImage(image, x, y, width, height);
        }
      }

      // Show Super Chat
      if (superchat) {

        let chatY = superchatY;
        // const chatWidth = 650;
        const chatHeight = 220;
        const list = data.comments.reverse().filter((c, i) => i < 5).reverse();


        // Draw chat
        const x = superchatX;//width - chatWidth;

        ctx.fillStyle = superchatColor;
        ctx.fillRect(x, chatY, 500, chatHeight);


        for (const index in list) {

          const comment = list[index];
          const y = chatY + (index * 40) + 30;

          const fontSize = 18;

          ctx.fillStyle = "#FFFFFF";
          ctx.textAlign = "left";
          ctx.font = `bold ${fontSize}pt Arial`;
          // if (comment.platform === "facebook") ctx.drawImage(icons.facebook, x + 28, y - 14, 25, 25);
          // else if (comment.platform === "youtube") ctx.drawImage(icons.youtube, x + 28, y - 14, 25, 25);

          ctx.fillText(comment.name, x + 75, y);
          ctx.font = "18pt Arial";
          ctx.fillText(comment.message, x + 90 + parseInt(comment.name.length * fontSize * 0.80), y);

          // profile icon and clip it in round borders
          const px = x + 10;
          const py = y - 24;
          const pSize = 26;
          const radius = pSize / 1.5;
          ctx.beginPath();
          ctx.moveTo(px + radius, py);
          ctx.lineTo(px + pSize - radius, py);
          ctx.quadraticCurveTo(px + pSize, py, px + pSize, py + radius);
          ctx.lineTo(px + pSize, py + pSize - radius);
          ctx.quadraticCurveTo(px + pSize, py + pSize, px + pSize - radius, py + pSize);
          ctx.lineTo(px + radius, py + pSize);
          ctx.quadraticCurveTo(px, py + pSize, px, py + pSize - radius);
          ctx.lineTo(px, py + radius);
          ctx.quadraticCurveTo(px, py, px + radius, py);
          ctx.closePath();

          // save your canvas state using save() before calling clip()
          ctx.save();
          ctx.clip();
          const img = await loadImage(comment.image)
          img.style.borderRadius = "50%";
          ctx.drawImage(img, px, py, pSize, pSize);

          //remove clipping by restoring canvas state to previous
          ctx.restore();
        }
      }

      // Show Popup
      // if (popup) {

      // }

      const imageData = ctx.getImageData(0, 0, 1280, 720);
      const zoomData = await zoomSdk.drawImage({
        x: 0,
        y: 0,
        imageData: imageData,
        zIndex: 3,
      });
      setZoomImageId(zoomData.imageId);

    } catch (e) {
      console.log(e);
    }


  }

  const onClearScene = () => {
    const ctx = document.getElementById('canvas').getContext("2d");
    if (zoomImageId) zoomSdk.clearImage({ imageId: zoomImageId });
    ctx.clearRect(0, 0, 1280, 720);
  }

  const onLayout = async (layout) => {
    const canvas = document.getElementById('canvas-layout');
    const ctx = canvas.getContext('2d');

    let name = "Zoom User";
    try {
      const zoomUser = await zoomSdk.getUserContext();
      name = zoomUser.screenName;
    } catch (e) {
      console.log(e.message);
    }

    // clear scene
    const width = 1280, height = 720;
    try {
      if (layoutImageId) zoomSdk.clearImage({ imageId: layoutImageId });
    } catch (e) {
      console.log(e);
    } finally {
      ctx.clearRect(0, 0, width, height);
    }


    const fontsize = 20;
    let x = 0, y = 0;
    if (!layout) return;
    if (layout === 1) {
      x = 50;
      y = height - 50 - (fontsize);
    } else if (layout === 2) {
      x = 50;
      y = height - 50 - (fontsize);
    } else if (layout === 3) {
      x = width - 50 - (name.length * fontsize);
      y = height - 50 - (fontsize);
    }

    try {
      ctx.fillStyle = "yellow";
      ctx.textAlign = "left";
      ctx.font = `bold ${fontsize}pt Arial`;
      ctx.textBaseline = "middle";
      ctx.fillText(name, x, y);

      const imageData = ctx.getImageData(0, 0, 1280, 720);
      const zoomData = await zoomSdk.drawImage({
        x: 0,
        y: 0,
        imageData: imageData,
        zIndex: 3,
      });
      setLayoutImageId(zoomData.imageId);

    } catch (e) {
      console.log(e.message);
    }

  }


 

  return (
    <div>
      <div className="home">
        {/* Card container */}
        <div className="home-card">
          {/* <div className="top-right">
            <MdLogout className="logout-icon" onClick={handleLogout} />
          </div> */}

          <img src={logo} alt="Logo" className="card-logo" /> {/* Logo moved inside the card and class name changed */}

          <h2>Welcome to Stream BOS</h2>

          <h4>Name Tags</h4>
          <section style={{ display: "flex", margin: "auto 0", textAlign: "center", alignContent: "center" }}>
            <button onClick={() => onLayout(1)} style={{ margin: 10, cursor: "pointer" }}>
              <img src="layout1.png" width={70} alt="layout" />
            </button>
            <button onClick={() => onLayout(2)} style={{ margin: 10, cursor: "pointer" }}>
              <img src="layout2.png" width={70} alt="layout" />
            </button>
            <button onClick={() => onLayout(3)} style={{ margin: 10, cursor: "pointer" }}>
              <img src="layout3.png" width={70} alt="layout" />
            </button>
            <button onClick={() => onLayout(0)} style={{ margin: 10, cursor: "pointer" }}>
              <img src="clear.png" width={70} alt="layout" />
            </button>
          </section>

          <br /><hr />
          <h4>Custom Layouts</h4>

          <select value={project} onChange={(e) => setProject(e.target.value)}>
            <option value="-1">Select Project</option>
            {projects.map(p => <option key={p._id} value={p._id}>{p.title}</option>)}
          </select>


          <select value={scene} onChange={(e) => setScene(e.target.value)}>
            <option id="-1">Select Scene</option>
            {scenes.map(p => <option key={p._id} value={p._id}>{p.title}</option>)}
          </select>

          <br />
          <button onClick={onShowScene} className="button-start">Show Scene</button>
          <button onClick={onClearScene} className="button-stop">Clear Scene</button>
          <br />
          <button onClick={async () => await zoomSdk.openUrl({ url: "https://web.streambos.com" })} className="button-stop">Open Dashboard</button>
        </div>


      </div>

      {/* <canvas width={1280} height={720} id="canvas" ref={refCanvas}></canvas> */}
      <canvas style={{ position: "absolute", display: "none" }} width={1280} height={720} id="canvas" ref={refCanvas}></canvas>

      {/* For Layout */}
      <canvas style={{}} width={1280} height={720} id="canvas-layout"></canvas>
    </div >
  );
};

export default Home;
