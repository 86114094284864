import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import SignUp from './components/Signup';
import Home from './components/Home';
import VirtualCamera from './components/VirtualCamera';
import zoomSdk from "@zoom/appssdk";
import ZoomWebView from './components/ZoomWebView';
export const ContextPage = React.createContext(0);
export const ContextSetPage = React.createContext(0);
export const ContextUser = React.createContext(null);
export const ContextSetUser = React.createContext((user) => null);


const App = () => {


  const [page, setPage] = useState(-1);
  const [user, setUser] = useState(null);

  // Configure Zoom App SDK
  useEffect(() => {
    async function run() {
      try {
        await zoomSdk.config({
          size: { width: 1280, height: 720 },
          capabilities: [

            // Drawing
            `runRenderingContext`,
            `closeRenderingContext`,
            `drawParticipant`,
            `clearParticipant`,
            `drawImage`,
            `clearImage`,
            `drawWebView`,
            `clearWebview`,


            "authorize",
            "onAuthorized",

            // Reactions
            "onEmojiReaction",
            "onReaction",
            "onMyReaction",



            // Messaging
            "connect",
            `postMessage`,
            `onMessage`,
          ]
        });

        // inMeeting The app is running in the right side panel of a meeting.
        const data = await zoomSdk.getRunningContext();
        if (data.context === "inMeeting") {
          await zoomSdk.runRenderingContext({ view: "camera" });
          const response = await zoomSdk.connect();
          console.log(response)
          return setPage(0);
        }
      } catch (e) {
        console.log(e);
        console.log("Error Not a Zoom Browser");
      }  
    }
    run();
  }, []);


  return (
    <ContextPage.Provider value={page}>
      <ContextSetPage.Provider value={setPage}>
        <ContextUser.Provider value={user}>
          <ContextSetUser.Provider value={setUser}>
            {page === -1 ? <ZoomWebView /> : null}
            {page === 0 ? <Login /> : null}
            {page === 1 ? <SignUp /> : null}
            {page === 2 ? <Home /> : null}
            {page === 3 ? <VirtualCamera /> : null}
            {page === 4 ? <ZoomWebView /> : null}

            {/* <Router>
                  <Routes>
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/camera" element={<VirtualCamera />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/" element={<Login />} />
                  </Routes>
                </Router> 
            */}


          </ContextSetUser.Provider>
        </ContextUser.Provider>
      </ContextSetPage.Provider>
    </ContextPage.Provider>
  );
};

export default App;
