import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import './VirtualCamera.css'; // Ensure this CSS file is correctly linked
import 'bootstrap/dist/css/bootstrap.css';
import zoomSdk from "@zoom/appssdk";
import emojis from '../utils/emojis.json';
import audio from '../audio/tone.mp3';
import zoomIcon from '../images/icons/zoom.png';
import { Alert, Button, Container, Row, Col } from 'react-bootstrap';
import { FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import { FaEye, FaPlay } from 'react-icons/fa';
import ReactGPicker from 'react-gcolor-picker';
import { AnimationEmpty } from './Lottie';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AiFillSetting, AiOutlineComment } from 'react-icons/ai';
import { FaBell, FaFilter, FaMoneyBill, FaPause, FaStar } from 'react-icons/fa';
import { BsEmojiSmile, BsFillEmojiLaughingFill } from 'react-icons/bs';
import { FaPowerOff } from "react-icons/fa";
import * as API from '../utils/api';
import { ContextSetPage } from '../App';


const icons = {
  'youtube': zoomIcon,// youtubeIcon,
  "facebook": zoomIcon //facebookIcon
}


const VirtualCamera = () => {

  const setPage = useContext(ContextSetPage);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [superchat, setSuperChat] = useState(false);
  const reducer = (state, action) => {
    const comments = [...state.comments];
    comments.push(
      ...action.comments.filter(c => !comments.find(comment => comment.id === c.id))
    )
    return { comments: comments }
  }

  const [data, dispatch] = useReducer(reducer, {
    comments: [
      { id: "1", platform: "facebook", timestamp: Date.now(), name: "Bob", message: "React is awesome.", image: "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "2", platform: "facebook", timestamp: Date.now(), name: "Charlie", message: "How's it going?", image: "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "3", platform: "youtube", timestamp: Date.now(), name: "Diana", message: "Looking good, React!", image: "https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "4", platform: "youtube", timestamp: Date.now(), name: "Eve", message: "This is a message.", image: "https://images.unsplash.com/photo-1518806118471-f28b20a1d79d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" },
      { id: "5", platform: "facebook", timestamp: Date.now(), name: "Frank", message: "Virtual meeting, cool!", image: "https://th.bing.com/th/id/R.7ad7ae3636feb990aa628793dc1164bc?rik=uxYefRShHexb%2fw&pid=ImgRaw&r=0&sres=1&sresct=1" },
      { id: "0", platform: "youtube", timestamp: Date.now(), name: "Alice", message: "Hello, world!", image: "https://th.bing.com/th/id/R.469f512b75a6e3dfca27d3ff0e784b04?rik=v3H1Ojg653JMDg&pid=ImgRaw&r=0&sres=1&sresct=1" }
    ]
  });
  const audioRef = useRef();
  const refCanvas = useRef();
  const [search, setSearch] = useState("");

  // Settings
  const [overlayShow, setOverlayShow] = useState(true);
  const [overlayContainerColor, setOverlayContainerColor] = useState("#00000022");
  const [overlaySmallFont, setOverlaySmallFont] = useState(false);
  const [overlayDarkMode, setOverlayDarkMode] = useState(false);

  const [messageHide, setMessageHide] = useState(false);
  const [messageBottom, setMessageBottom] = useState(false);
  const [messageReadNoChatName, setMessageReadNoChatName] = useState(false);
  const [messageRead3rd, setMessageRead3rd] = useState(false);
  const [messageReload50, setMessageReload50] = useState(false);
  const [messageReloadSelectDonation, setMessageSelectDonation] = useState(false);

  const [messageVisualIcons, setMessageVisualIcons] = useState(false);
  const [messageVisualChannelIcons, setMessageVisualChannelIcons] = useState(false);
  const [messageVisualAvatars, setMessageVisualAvatars] = useState(false);
  const [messageVisualTimestamp, setMessageVisualTimestamp] = useState(false);
  const [messageVisualFirstNames, setMessageVisualFirstNames] = useState(false);
  const [messageVisualNoNames, setMessageVisualNoNames] = useState(false);
  const [messageVisualColorNames, setMessageVisualColorNames] = useState(false);
  const [messageVisualHistory200, setMessageVisualHistory200] = useState(false);
  const [messageVisualBadKarma, setMessageVisuaBadKarma] = useState(false);
  const [messageVisualOnlyQuestions, setMessageVisuaOnlyQuestions] = useState(false);
  const [messageVisualNoQuestions, setMessageVisuaNoQuestions] = useState(false);


  // Settings
  const [pause, setPause] = useState(false);
  const [sound, setSound] = useState(false);
  const [filter, setFilter] = useState(false);
  const [emojiFilter, setEmojiFilter] = useState(false);
  const [moneyFilter, setMoneyFilter] = useState(false);
  const [memberFilter, setMemberFilter] = useState(false);
  const [read, setRead] = useState(false);
  const [settings, setSettings] = useState(false);



  // Emote Wall
  const [emoteNoDuplicates, setEmoteNoDuplicates] = useState(false);
  const [emoteOnly10, setEmoteOnly10] = useState(false);
  const [emoteSeconds20, setEmoteSeconds20] = useState(false);
  const [emoteDoubleEmoteSpeed, setEmoteDoubleEmoteSpeed] = useState(false);
  const [emoteHalfSize, setEmoteHalfSize] = useState(false);
  const [emoteExcludeAtReplies, setEmoteExcludeAtReplies] = useState(false);

  const [layoutImageId, setLayoutImageId] = useState("");


  //project and scenes
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("-1");
  const [scenes, setScenes] = useState([])
  const [scene, setScene] = useState("-1");
  const [zoomSceneImageId, setZoomSceneImageId] = useState('');


  // Automatically Draw Webview
  useEffect(() => {
    zoomSdk.drawWebView({ x: 0, y: 0, width: 1280, height: 720, zIndex: 2 })
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  }, [])


  useEffect(() => {
    zoomSdk.onReaction((event) => {
      if (!emojiFilter) return;
      console.log(event);
    })
  }, [emojiFilter])

  // Get Projects
  useEffect(() => {
    API.GetAPI('/api/projects').then(setProjects);
  }, [])


  // Get Scenes for selected project
  useEffect(() => {
    if (project !== '-1') {
      API.GetAPI(`/api/projects/${project}/scenes`).then(setScenes);
    } else setScenes([]);
  }, [project])


  function loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img)
    })
  }


  const onShowScene = async () => {
    try {
      onClearScene();
      const ctx = document.getElementById('canvas-scenes').getContext("2d");
      const elements = await API.GetAPI(`/api/projects/${project}/scenes/${scene}/elements`);

      const s = scenes.find(s => s._id == scene);
      const { superchat, superchatX, superchatY, superchatColor, popup, popupX, popupY } = s;

      for (const ele of elements) {
        const { x, y, width, height, color, type, text, url } = ele;
        const fontSize = 19;
        if (type === 'text') {
          ctx.fillStyle = color;
          ctx.textAlign = "left";
          ctx.font = `bold ${fontSize}pt Arial`;
          ctx.textBaseline = "middle";
          ctx.fillText(text, x, y);
        } else if (type === 'rect') {
          ctx.fillStyle = color;
          ctx.fillRect(x, y, width, height);
        } else if (type === 'circle') {
          // Add a purple circle just before the name text
          const radius = 40;
          ctx.fillStyle = color;
          ctx.beginPath();
          ctx.arc(x + 10, y + width / 2 + (radius / 2), height / 2, 0, Math.PI * 2);
          ctx.fill();
        } else if (type === 'image') {
          const image = await loadImage(url);
          ctx.drawImage(image, x, y, width, height);
        }
      }

      // Show Super Chat
      if (superchat) {

        let chatY = superchatY;
        // const chatWidth = 650;
        const chatHeight = 220;
        const list = data.comments.filter((c, i) => i < 5);


        // Draw chat
        const x = superchatX;//width - chatWidth;

        ctx.fillStyle = superchatColor;
        ctx.fillRect(x, chatY, 500, chatHeight);


        for (const index in list) {

          const comment = list[index];
          const y = chatY + (index * 40) + 30;

          const fontSize = 18;

          ctx.fillStyle = "#FFFFFF";
          ctx.textAlign = "left";
          ctx.font = `bold ${fontSize}pt Arial`;
          // if (comment.platform === "facebook") ctx.drawImage(icons.facebook, x + 28, y - 14, 25, 25);
          // else if (comment.platform === "youtube") ctx.drawImage(icons.youtube, x + 28, y - 14, 25, 25);

          ctx.fillText(comment.name, x + 75, y);
          ctx.font = "18pt Arial";
          ctx.fillText(comment.message, x + 90 + parseInt(comment.name.length * fontSize * 0.80), y);

          // profile icon and clip it in round borders
          const px = x + 10;
          const py = y - 24;
          const pSize = 26;
          const radius = pSize / 1.5;
          ctx.beginPath();
          ctx.moveTo(px + radius, py);
          ctx.lineTo(px + pSize - radius, py);
          ctx.quadraticCurveTo(px + pSize, py, px + pSize, py + radius);
          ctx.lineTo(px + pSize, py + pSize - radius);
          ctx.quadraticCurveTo(px + pSize, py + pSize, px + pSize - radius, py + pSize);
          ctx.lineTo(px + radius, py + pSize);
          ctx.quadraticCurveTo(px, py + pSize, px, py + pSize - radius);
          ctx.lineTo(px, py + radius);
          ctx.quadraticCurveTo(px, py, px + radius, py);
          ctx.closePath();

          // save your canvas state using save() before calling clip()
          ctx.save();
          ctx.clip();
          const img = await loadImage(comment.image)
          img.style.borderRadius = "50%";
          ctx.drawImage(img, px, py, pSize, pSize);

          //remove clipping by restoring canvas state to previous
          ctx.restore();
        }
      }

      // Show Popup
      // if (popup) {

      // }

      const imageData = ctx.getImageData(0, 0, 1280, 720);
      const zoomData = await zoomSdk.drawImage({
        x: 0,
        y: 0,
        imageData: imageData,
        zIndex: 3,
      });
      setZoomSceneImageId(zoomData.imageId);

    } catch (e) {
      console.log(e);
    }


  }

  const onClearScene = () => {
    const ctx = document.getElementById('canvas-scenes').getContext("2d");
    if (zoomSceneImageId) zoomSdk.clearImage({ imageId: zoomSceneImageId });
    ctx.clearRect(0, 0, 1280, 720);
  }



  const filterComment = useCallback((comment) => {
    if (moneyFilter && !comment.donation) return false;
    if (memberFilter && !comment.member) return false;
    if (filter && search) {
      if (
        comment.message.toLowerCase().indexOf(search.toLowerCase()) === -1
        &&
        comment.name.toLowerCase().indexOf(search.toLowerCase()) === -1
      ) return false;
    }
    if (emojiFilter) {
      for (const emoji of emojis) {
        if (comment.message.indexOf(emoji) !== -1) return false;
      }
    }
    return true;
  }, [filter, emojiFilter, moneyFilter, memberFilter, search])



  const onSuperChat = async () => {
    zoomSdk.postMessage({ zid: "chats" });//wordk in ZoomWebView
    setSuperChat(!superchat);
  }

  // Define variables for the rectangle
  async function drawPill(cm) {
    zoomSdk.postMessage({ zid: "comment", ...cm }); // wordk in ZoomWebView
  }

  const onLayout = async (layout) => {
    const canvas = document.getElementById('canvas-layout');
    const ctx = canvas.getContext('2d');

    let name = "Zoom User";
    try {
      const zoomUser = await zoomSdk.getUserContext();
      name = zoomUser.screenName;
    } catch (e) {
      console.log(e.message);
    }

    // clear scene
    const width = 1280, height = 720;
    try {
      if (layoutImageId) zoomSdk.clearImage({ imageId: layoutImageId });
    } catch (e) {
      console.log(e);
    } finally {
      ctx.clearRect(0, 0, width, height);
    }


    const fontsize = 20;
    let x = 0, y = 0;
    if (!layout) return;
    if (layout === 1) {
      x = 50;
      y = height - 50 - (fontsize);
    } else if (layout === 2) {
      x = 50;
      y = height - 50 - (fontsize);
    } else if (layout === 3) {
      x = width - 50 - (name.length * fontsize);
      y = height - 50 - (fontsize);
    }

    try {
      ctx.fillStyle = "yellow";
      ctx.textAlign = "left";
      ctx.font = `bold ${fontsize}pt Arial`;
      ctx.textBaseline = "middle";
      ctx.fillText(name, x, y);

      const imageData = ctx.getImageData(0, 0, 1280, 720);
      const zoomData = await zoomSdk.drawImage({
        x: 0,
        y: 0,
        imageData: imageData,
        zIndex: 3,
      });
      setLayoutImageId(zoomData.imageId);

    } catch (e) {
      console.log(e.message);
    }

  }


  async function drawEmoji() {
    zoomSdk.postMessage({ zid: "emojis" });//wordk in ZoomWebView
  }

  const onLogout = async () => {
    try {
      const res = await API.PostAPI('/api/zoom/logout');
      if (res.result) {
        API.setAccessToken("");
        setPage(0);
      }
    } catch (e) {
      console.log(e.message);
    }
  }


  return (
    <div>
      {
        !data.comments.length ?
          <div>
            {/* <AppBar showControls={true} /> */}
            <br /><br /><br />
            <Container className="centalise" >
              <AnimationEmpty title="No Comments Yet" width={400} />
            </Container>

          </div> :

          <div>
            <div style={{ display: "flex", background: "#000111", }}>
              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">{pause ? "Play" : "Pause"} Incoming Comments</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setPause(!pause)}>
                  {pause ? <FaPlay size={20} /> : <FaPause size={20} />}
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Draw Chats</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={onSuperChat}>
                  <AiOutlineComment color={superchat ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>



              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Beep on new message</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setSound(!sound)}>
                  <FaBell color={sound ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Filter Messages</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setFilter(!filter)}>
                  <FaFilter color={filter ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Hide Messages with Emojis</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setEmojiFilter(!emojiFilter)}>
                  <BsFillEmojiLaughingFill color={emojiFilter ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Filter Messages with Donations</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setMoneyFilter(!moneyFilter)}>
                  <FaMoneyBill color={moneyFilter ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Filter Messages from members</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setMemberFilter(!memberFilter)}>
                  <FaStar color={memberFilter ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="settings">Chat Settings</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setSettings(!settings)}>
                  <AiFillSetting color={settings ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>

              {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="chats">Auto reply to messages</Tooltip>}>
                            <Button size='sm' variant="dark" className="round hover" onClick={onAutoMessage}>
                                <FaAutoprefixer size={20} />
                            </Button>
                        </OverlayTrigger> */}

              {/* <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Text to speech as messages come in</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={() => setRead(!read)}>
                  <AiFillAudio color={read ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger> */}

              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="chats">Logout</Tooltip>}>
                <Button size='sm' variant="dark" className="round hover" onClick={onLogout}>
                  <FaPowerOff color={read ? "blue" : ""} size={20} />
                </Button>
              </OverlayTrigger>
            </div>
            <audio src={audio} ref={audioRef} style={{ position: "absolute", display: "none" }} />
            <TextField style={{ display: filter ? "block" : "none", width: "100%" }} size="small" type='search' label="Comment Search..." placeholder='Search...' value={search} onChange={e => setSearch(e.target.value)} />

            <h6>Name Tags</h6>

            <section style={{ display: "flex", margin: "auto 0", textAlign: "center", alignContent: "center" }}>
              <button onClick={() => onLayout(1)} style={{ padding: 4, margin: 10, cursor: "pointer" }}>
                <img src="layout1.png" width={70} alt="layout" style={{ borderRadius: 10 }} />
              </button>
              <button onClick={() => onLayout(2)} style={{ padding: 4, margin: 10, cursor: "pointer" }}>
                <img src="layout2.png" width={70} alt="layout" style={{ borderRadius: 10 }} />
              </button>
              <button onClick={() => onLayout(3)} style={{ padding: 4, margin: 10, cursor: "pointer" }}>
                <img src="layout3.png" width={70} alt="layout" style={{ borderRadius: 10 }} />
              </button>
              <button onClick={() => onLayout(0)} style={{ padding: 4, margin: 10, cursor: "pointer" }}>
                <img src="clear.png" width={70} alt="layout" style={{ borderRadius: 10 }} />
              </button>
            </section>

            <h6>Chat</h6>
            <Container fluid className="centalise" >
              {
                data.comments.filter(filterComment).map(comment =>
                  <Alert key={comment.id} variant="light" className="dropShadow" style={{ borderRadius: 20, padding: 2 }}>
                    <Alert.Heading >
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", marginRight: 10 }}>
                          <img src={icons[comment.platform]} alt={comment.name} width={30} height={30} />
                          <img style={{ borderRadius: "50%", fontSize: 10 }} src={comment.image} alt={comment.name} width={30} height={30} />
                          <h4 style={{ marginLeft: 10, textAlign: "left" }}><b>{comment.name}</b></h4>
                        </div>
                        <div style={{ marginRight: 10, color: " #007bff", fontSize: 9 }}>
                          <h5><b>{comment.message}</b></h5>
                        </div>
                        <h6 style={{ fontSize: 9 }} ><i>{new Date(comment.timestamp).toDateString()}</i></h6>
                        <Row style={{ paddingLeft: 20 }} xs={3}>
                          <Col>
                            <Button style={{
                              padding: "3px",
                              height: "32px",
                            }}
                              onClick={() => drawPill(comment)} size="sm" variant="primary" className="round hover" title="Show In Virtual Cam"><FaEye /></Button>

                          </Col>

                          {/* <Col>
                            <Button style={{
                              padding: "3px",
                              height: "32px",
                            }}
                              onClick={onClearPill} size="sm" variant="dark" className="round hover" title="Show In Virtual Cam"><FaEye /></Button>
                          </Col> */}


                          <Col>
                            <Button style={{
                              padding: "3px",
                              height: "32px",
                            }}
                              onClick={() => drawEmoji(comment)} size="sm" variant="primary" className="round hover" title="Show In Emojis"><BsEmojiSmile /></Button>

                          </Col>
                        </Row>
                      </div>
                    </Alert.Heading>
                  </Alert>
                )
              }
            </Container>
            <canvas style={{ position: "absolute", display: "none" }} width={1280} height={720} id="canvas" ref={refCanvas}></canvas>
            {/* <canvas width={1280} height={720} id="canvas" ref={refCanvas}></canvas> */}

            {/* For Layout */}
            <canvas style={{ position: "absolute", display: "none" }} width={1280} height={720} id="canvas-layout"></canvas>
            {/* <canvas style={{}} width={1280} height={720} id="canvas-layout"></canvas> */}

            {/* For Predefined Scene */}
            <canvas style={{ position: "absolute", display: "none" }} width={1280} height={720} id="canvas-scenes"></canvas>
            {/* <canvas width={1280} height={720} id="canvas" ref={refCanvas}></canvas> */}

          </div>
      }


      {settings &&
        <Container style={{ position: "fixed", top: 80, right: 0, maxWidth: 500, height: "80%", overflowY: "scroll", background: "#ffffffde" }}>
          <h1>Chat Settings</h1>
          <br />
          <h3>Overlay</h3>
          <hr />

          <select value={project} onChange={(e) => setProject(e.target.value)}>
            <option value="-1">Select Project</option>
            {projects.map(p => <option key={p._id} value={p._id}>{p.title}</option>)}
          </select>


          <select value={scene} onChange={(e) => setScene(e.target.value)}>
            <option id="-1">Select Scene</option>
            {scenes.map(p => <option key={p._id} value={p._id}>{p.title}</option>)}
          </select>

          <br />
          <button onClick={onShowScene} className="button-start">Show Scene</button>
          <button onClick={onClearScene} className="button-stop">Clear Scene</button>
          <br />
          <button onClick={async () => await zoomSdk.openUrl({ url: "https://web.streambos.com" })} className="button-stop">Open Dashboard</button>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={overlayShow} onChange={() => setOverlayShow(!overlayShow)} />}
              label="Show Chat Overlay" />
          </FormGroup>


          <br />
          {/* <TextField label="Chat Background Color" style={{ width: "100%" }} size="small" type="color" value={overlayContainerColor} onChange={e => setOverlayContainerColor(e.target.value)} /> */}
          <h6>Chat Background Color</h6>
          <ReactGPicker value={overlayContainerColor} onChange={(value) => { console.log(value); setOverlayContainerColor(value) }} />
          <br />

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={overlaySmallFont} onChange={() => setOverlaySmallFont(!overlaySmallFont)} />}
              label="Small Font" />
          </FormGroup>


          <FormGroup>
            <FormControlLabel
              control={<Switch checked={overlayDarkMode} onChange={() => setOverlayDarkMode(!overlayDarkMode)} />}
              label={overlayDarkMode ? "Light Mode" : "Dark Mode"} />
          </FormGroup>
          <br />
          <h3>Message Mechanics</h3>
          <hr />
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageHide} onChange={() => setMessageHide(!messageHide)} />}
              label="Hide messages after 20s" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageBottom} onChange={() => setMessageBottom(!messageBottom)} />}
              label="Messages start at bottom rather than top" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageReadNoChatName} onChange={() => setMessageReadNoChatName(!messageReadNoChatName)} />}
              label="Do not read out chatname" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageRead3rd} onChange={() => setMessageRead3rd(!messageRead3rd)} />}
              label="Only Read Out Third Message" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageReload50} onChange={() => setMessageReload50(!messageReload50)} />}
              label="Reload the last ~50-messages when refreshing the page" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageReloadSelectDonation} onChange={() => setMessageSelectDonation(!messageReloadSelectDonation)} />}
              label="When auto-selecting is on, only auto-select donations" />
          </FormGroup>
          <br />
          <h3>Message Mechanics</h3>
          <hr />
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualIcons} onChange={() => setMessageVisualIcons(!messageVisualIcons)} />}
              label="Hide source icon" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualChannelIcons} onChange={() => setMessageVisualChannelIcons(!messageVisualChannelIcons)} />}
              label="Show channel icon" />
          </FormGroup>


          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualAvatars} onChange={() => setMessageVisualAvatars(!messageVisualAvatars)} />}
              label="Hide avatars" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualTimestamp} onChange={() => setMessageVisualTimestamp(!messageVisualTimestamp)} />}
              label="Hide timestamp" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualFirstNames} onChange={() => setMessageVisualFirstNames(!messageVisualFirstNames)} />}
              label="First Names Only" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualNoNames} onChange={() => setMessageVisualNoNames(!messageVisualNoNames)} />}
              label="Dont Show Name" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualColorNames} onChange={() => setMessageVisualColorNames(!messageVisualColorNames)} />}
              label="Color Names" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualHistory200} onChange={() => setMessageVisualHistory200(!messageVisualHistory200)} />}
              label="Increase messages history to 200" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualBadKarma} onChange={() => setMessageVisuaBadKarma(!messageVisualBadKarma)} />}
              label="🤬 Filter out messages with bad karma" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualOnlyQuestions} onChange={() => setMessageVisuaOnlyQuestions(!messageVisualOnlyQuestions)} />}
              label="❓ Only show messages marked as questions" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={messageVisualNoQuestions} onChange={() => setMessageVisuaNoQuestions(!messageVisualNoQuestions)} />}
              label="❔ Hide messages marked as questions" />
          </FormGroup>

          <br />
          <h3>Emote Wall</h3>
          <hr />


          <FormGroup>
            <FormControlLabel
              control={<Switch checked={emoteNoDuplicates} onChange={() => setEmoteNoDuplicates(!emoteNoDuplicates)} />}
              label="👯🚫 Filter out duplicate emotes per message" />
          </FormGroup>


          <FormGroup>
            <FormControlLabel
              control={<Switch checked={emoteOnly10} onChange={() => setEmoteOnly10(!emoteOnly10)} />}
              label="🔢 Limit to 10 emotes at a time" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={emoteSeconds20} onChange={() => setEmoteSeconds20(!emoteSeconds20)} />}
              label="⏳ Show messages for 20s, rather than 5s." />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={emoteDoubleEmoteSpeed} onChange={() => setEmoteDoubleEmoteSpeed(!emoteDoubleEmoteSpeed)} />}
              label="⏩ Double the emotes' movement speed" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={emoteHalfSize} onChange={() => setEmoteHalfSize(!emoteHalfSize)} />}
              label="🤏 Make all emojis half the size" />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={emoteExcludeAtReplies} onChange={() => setEmoteExcludeAtReplies(!emoteExcludeAtReplies)} />}
              label="🗣🚫️ Exclude message @replies emojies" />
          </FormGroup>


        </Container>
      }

    </div>
  )
};

export default VirtualCamera;
