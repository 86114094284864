// src/components/SignUp.js
import React, { useContext } from 'react';
 
import logo from './Boss_logo.png'; // Importing the logo from the same directory
import Swal from 'sweetalert2';
import './AuthForm.css';
import {  ContextSetPage } from '../App';


const SignUp = () => {
  const setPage = useContext(ContextSetPage);



  // let navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Success',
      text: 'Account created successfully!',
      icon: 'success',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
        // navigate('/login');
        setPage(0)// login
      }
    });
  };

  return (
    <div className="auth-form">
       <img src={logo} alt="Logo" className="form-logo" /> {/* Display the logo */}
      <h2>Sign Up</h2>
      <form onSubmit={handleSignUp}>
        <input type="text" placeholder="Username" required />
        <input type="email" placeholder="Email" required />
        <input type="password" placeholder="Password" required />
        <input type="password" placeholder="Confirm Password" required />
        <button type="submit">Create Account</button>
        <p onClick={() => setPage(0)}>Already have an account? Login.</p>
      </form>
    </div>
  );
};

export default SignUp;
