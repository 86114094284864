/* eslint-disable no-undef */

import axios from "axios";
import Cookies from 'js-cookie';
const instance = axios.create();
const authUrl =  'https://app.mysocial360.com/streambos'; //Get Urls for APIs

export const REACT_APP_BACKEND = authUrl;
export const REACT_APP_FACEBOOK_APP_ID = '4615467638465272'
export const REACT_APP_COOKIE_KEY = 'innercircledeets_cookies'
export const REACT_APP_DASHBOARD_URL = 'https://dashboard.mysocial360.com'

export const GetAPI = (route, auth = true, config = {}) => Get(`${authUrl}${route}`, auth, config);
export const PostAPI = (route, body = {}, auth = true) => Post(`${authUrl}${route}`, body, auth);
export const PatchAPI = (route, body = {}, auth = true) => Patch(`${authUrl}${route}`, body, auth);
export const DeleteAPI = (route, auth = true) => Delete(`${authUrl}${route}`, auth);

export function Get(url, auth = true, config = {}) {
    return new Promise(async resolve => {
        const chrome = "";
        const cookie = await getAffiliateCookie();
        const notificaitionId = "";

        if (auth) {
            //refresh user if necessary
            const token = await getAccessToken();
            const headers = {
                'Authorization': 'Bearer ' + token,
                'boscookies': cookie,
                'bosnotification': notificaitionId,
                'boschrome': chrome,
                ...config
            };

            try {
                const response = await instance.get(url, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }
        } else {
            try {

                const headers = { 'boscookies': cookie, 'bosnotification': notificaitionId, 'boschrome': chrome, };
                const response = await instance.get(url, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }
        }
    });
}

export function Post(url, body = {}, auth = true) {
    return new Promise(async resolve => {
        const chrome = "";
        const cookie = await getAffiliateCookie();
        const notificaitionId = "";


        if (auth) {

            //refresh user if necessary
            const token = await getAccessToken();

            const headers = {
                'Authorization': 'Bearer ' + token,
                'boscookies': cookie,
                'bosnotification': notificaitionId,
                'boschrome': chrome,
            };

            try {
                const response = await instance.post(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }

        } else {
            try {

                const headers = { 'boscookies': cookie, 'bosnotification': notificaitionId, 'boschrome': chrome, };
                const response = await instance.post(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }
        }
    });
}

export function Patch(url, body = {}, auth = true) {
    return new Promise(async resolve => {
        const chrome = "";
        const cookie = await getAffiliateCookie();
        const notificaitionId = "";


        if (auth) {

            //refresh user if necessary
            const token = await getAccessToken();

            const headers = {
                'Authorization': 'Bearer ' + token,
                'boscookies': cookie,
                'bosnotification': notificaitionId,
                'boschrome': chrome,
            };
            try {

                const response = await instance.patch(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }


        } else {
            try {

                const headers = { 'boscookies': cookie, 'bosnotification': notificaitionId };
                const response = await instance.patch(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }

        }
    });
}

export function Delete(url, auth = true) {
    return new Promise(async resolve => {
        const chrome = "";
        const cookie = await getAffiliateCookie();
        const notificaitionId = "";


        if (auth) {

            //refresh user if necessary
            const token = await getAccessToken();
            const headers = {
                'Authorization': 'Bearer ' + token,
                'boscookies': cookie,
                'bosnotification': notificaitionId,
                'boschrome': chrome,
            };
            try {
                const response = await instance.delete(url, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }

        } else {
            try {

                const headers = { 'boscookies': cookie, 'bosnotification': notificaitionId, 'boschrome': chrome };
                const response = await instance.delete(url, { headers });
                resolve(response.data);
            } catch (e) {
                if (e.response) {
                    if (e.response.status < 500) {
                        resolve(e.response.data);
                    } else {
                        resolve({ result: false, message: "Something Went Wrong" })
                    }
                } else {
                    resolve({ result: false, message: "Something Went Wrong" })
                }
            }
        }
    });
}



export function getAccessToken() {
    return new Promise(resolve => resolve(Cookies.get(REACT_APP_COOKIE_KEY)));
}


export function setAccessToken(token) {
    Cookies.set(REACT_APP_COOKIE_KEY, token);
}

function getAffiliateCookie() {
    return new Promise(resolve => {
        resolve("");
    });
}