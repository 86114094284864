import React, { useEffect } from 'react'
import zoomSdk from "@zoom/appssdk";
import './ZoomWebView.css'; // Ensure this CSS file is correctly linked

function ZoomWebView() {

  useEffect(() => {

    async function run() {

      // default state of elements
      const chat1 = document.getElementById("chats");
      chat1.style.visibility = "hidden";

      const secondPanel1 = document.getElementById('secondPanel');
      secondPanel1.style.display = 'none';


      // Listen For Messages
      zoomSdk.onMessage((data) => {

        // This work in the ZoomWebView.js
        const json = JSON.parse(data.payload.toString());


        if (json.zid === "chats") {

          // Toggle Chats
          const chat = document.getElementById("chats");
          if (chat.style.visibility !== "hidden") chat.style.visibility = "hidden";
          else chat.style.visibility = "visible";

        } else if (json.zid === "emojis") {

          const emojis = [
            '😊', '🌟', '🍀', '🎉', '🌈', '🌸', '🍍', '🌺', '🐾', '🌍',
            '🚀', '🎈', '🌞', '🌙', '🌼', '🍦', '🎵', '📚', '🎨', '🍕',
            '🚲', '🍁', '🏖️', '🌮', '🍔', '🎸', '🚁', '🚦', '🍓', '🍭',
            '🎭', '🏰', '🌋', '🚤', '🚁', '🎳', '🌄', '🌅', '🚀', '🌟'
          ];

          // Randomly select an emoji from the array
          var randomIndex = Math.floor(Math.random() * emojis.length);
          var selectedEmoji = emojis[randomIndex];

          // Create emoji element
          var emoji = document.createElement('div');
          emoji.classList.add('emoji');
          emoji.innerHTML = selectedEmoji;

          // Add emoji to the container
          document.body.appendChild(emoji);

          // Calculate random starting position
          var startX = window.innerWidth / 2; // Start from the center horizontally
          var startY = window.innerHeight / 2; // Start from the center vertically

          // Calculate random ending position
          var endX = Math.random() * window.innerWidth; // Random ending position horizontally
          var endY = Math.random() * window.innerHeight; // Random ending position vertically

          // Set initial position
          emoji.style.left = startX + 'px';
          emoji.style.top = startY + 'px';

          // Animate emoji
          emoji.animate([
            { transform: 'translate(0, 0)' },
            { transform: `translate(${endX - startX}px, ${endY - startY}px)` }
          ], {
            duration: 2000, // Animation duration (in milliseconds)
            easing: 'ease-out', // Animation easing
            fill: 'forwards' // Maintain final state after animation
          });

          // Remove emoji from DOM after animation completes
          emoji.onanimationend = () => emoji.remove();
          setTimeout(emoji.remove, 4000);

          
        } else if (json.zid === "comment") {
          const secondPanel = document.getElementById('secondPanel');


          if (secondPanel.style.display === 'none') {
            // Show the second panel with animation
            secondPanel.querySelector('span').textContent = json.name;
            secondPanel.querySelector('p').textContent = json.message;
            secondPanel.style.display = 'block';
            secondPanel.classList.add('slide-up-animation');
          } else {
            // Show the second panel with animation
            secondPanel.style.display = 'none';
            secondPanel.classList.remove('slide-up-animation');
          }

        }
      });
    }
    setTimeout(run, 5000)
  }, []);

  return (

    <div>
      <div id="chats" className="panel">
        <ul class="name-list">
          <li>
            <div class="person">
              <img className="zoom" src="images/zoom.png" alt='zoom' />
              <img src="https://th.bing.com/th/id/R.469f512b75a6e3dfca27d3ff0e784b04?rik=v3H1Ojg653JMDg&pid=ImgRaw&r=0&sres=1&sresct=1" alt="Person 1" />
              <span><b id="name1">Bob</b> <i id="comment1">React is awesome.</i> </span>

            </div>
          </li>
          <li>
            <div class="person">
              <img className="zoom" src="images/zoom.png" alt='zoom' />
              <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="Person 2" />
              <span><b id="name2">Charlie</b> <i id="comment2">How's it going?</i> </span>
            </div>
          </li>
          <li>
            <div class="person">
              <img className="zoom" src="images/zoom.png" alt='zoom' />
              <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="Person 3" />
              <span><b id="name3">Diana</b> <i id="comment3">Looking good, React!</i>  </span>
            </div>
          </li>
          <li>
            <div class="person">
              <img className="zoom" src="images/zoom.png" alt='zoom' />
              <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="Person 4" />
              <span><b id="name4">Eve</b> <i id="comment4">This is a message.</i> </span>
            </div>
          </li>
          <li>
            <div class="person">
              <img className="zoom" src="images/zoom.png" alt='zoom' />
              <img src="https://images.unsplash.com/photo-1518806118471-f28b20a1d79d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="Person 5" />
              <span><b id="name5">Frank</b> <i id="comment5">Virtual meeting, cool!</i> </span>
            </div>
          </li>
        </ul>
      </div>

      {/* <!-- New panel --> */}
      <div className="panel" id="secondPanel">
        <div className="profile">
          <img src="images/p3.png" alt="Your" width="70" />
          <div className="info">
            <span><h1>Commenter</h1></span>
            <p><h4>Here is where the comment goes</h4></p>
          </div>
        </div>
      </div>

      {/* <!-- Emojis container --> */}
      <div className="emojis-container"></div>


    </div>
  )
}

export default ZoomWebView