import React, { useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Ensure SweetAlert2 is imported
import logo from './Boss_logo.png'; // Importing the logo from the same directory
import * as API from "../utils/api";
import { ContextSetPage, ContextSetUser, ContextUser } from '../App';
// import { FcGoogle } from 'react-icons/fc';
import zoomSdk from "@zoom/appssdk";
import './AuthForm.css';
import { BsCamera } from 'react-icons/bs';

const codeChallenge = '7a7ab574b18de5192c4dbf324808d14ddbe65fd45be17159e105bca389f944ff' //process.env.ZOOM_CODE_CHALLENGE;

const Login = () => {

  // const page = useContext(ContextPage);
  const setPage = useContext(ContextSetPage);
  const setUser = useContext(ContextSetUser);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // On Authorize Event - https://developers.zoom.us/docs/zoom-apps/authentication/
  //   zoomSdk.onAuthorized((event) => {
  //     // const { timestamp, code, redirectUri, result } = event;
  //     const { code } = event;
  //     zoomSdk.openUrl({ url: `https://app.mysocial360.com/streambos/api/google/oauth/zoom?zoom=${code}` });
  //     console.log(event);
  //   })
  // }, [])


  // Auto Login
  useEffect(() => {
    async function autoLogin() { 
      const accountId = window.location.href.replace(/.*\?|\&.*/gmi, '').replace(/.*\=|\#/, '');
      const res = await API.PostAPI(`/api/zoom/login`, { accountId });
      if (res.result) {
        setLoading(true)
        console.log('Logging Into Streambos')
        API.setAccessToken(res.access_token);
        const u = await API.GetAPI('/api/user');
        setUser(u.user);

        setPage(3);
        setLoading(false)
    
      }
      console.log(res);

    }
    autoLogin();
  }, [setPage, setUser])


  // let navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    const user = {
      email: e.target.email.value,
      password: e.target.password.value,
    }
    setLoading(true);
    try {
      const res = await API.PostAPI('/api/email/oauth', user);
      if (res.result) {
        const access_token = res.access_token;
        API.setAccessToken(access_token)
        const u = await API.GetAPI('/api/user');
        setUser(u.user);
        setPage(3);

      } else {
        // Display SweetAlert2 popup for Login failed
        Swal.fire({
          title: 'Something went wrong',
          text: res.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    // Dummy login functionality
    //navigate('/home'); // Navigate to Home page on login
    //home page
  };

  const handleForgotPassword = () => {
    // Display SweetAlert2 popup for Forgot Password
    Swal.fire({
      title: 'Reset Password',
      text: 'A link to reset your password has been sent to your email.',
      icon: 'info',
      confirmButtonText: 'Ok'
    });
  };

  const onZoomOauth = async () => {
    try {
      // // PKCE - https://devforum.zoom.us/t/zoom-oauth-2-0-proof-key-for-code-exchange-pkce-flow/63830
      // // Create codeChallenge and code verifer - https://emn178.github.io/online-tools/sha256.html
      // const result = await zoomSdk.callZoomApi("authorize", {
      //   codeChallenge: codeChallenge,
      // });

      setLoading(true);
      zoomSdk.openUrl({ url: "https://app.mysocial360.com/streambos/api/zoom/oauth" })
    } catch (e) {
      console.log(e.message)
    }

  }

  return (
    <div className="auth-form">
      <img src={logo} alt="Logo" className="form-logo" /> {/* Display the logo */}
      <h2>Stream BOS</h2>
      <form onSubmit={handleLogin}>
        <input type="email" name="email" placeholder="Email" required />
        <input type="password" name="password" placeholder="Password" required />
        <button disabled={loading} type="submit">{loading ? "Loading..." : "Login"}</button>
      </form>
      <button disabled={loading} onClick={onZoomOauth}>{loading ? "Loading..." : <div style={{ display: "flex" }}><BsCamera style={{ marginRight: 10 }} />Continue with Zoom</div>}</button>
      <p onClick={handleForgotPassword}>Forgot Password?</p> {/* Use onClick handler for Forgot Password */}
      <p onClick={() => setPage(1)}>Don't have an account? Sign up.</p>
    </div>
  );
};

export default Login;
